'use client';

import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { useCallback, useRef, useState } from 'react';

import { Button } from '@/components/button';
import { Image } from '@/components/image';
import { MaxWidth } from '@/components/max-width';

import { isImage } from '@/helpers/image';
import { render } from '@/helpers/storyblok/rich-text';
import { markdownToHtml } from '@/helpers/string';

import './index.css';

import { SupTitle } from '@/components/sup-title';

export const HeroHome = ({ blok }) => {
  const videoRef = useRef(null);

  const TitleEl = blok.titleLevel || 'h1';

  const [playing, setPlaying] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(true);

  const handlePlayPause = useCallback(() => {
    if (videoRef?.current) {
      try {
        if (playing) {
          videoRef.current.pause?.();
        } else {
          if (!hasPlayed) {
            videoRef.current.currentTime = 0;

            setHasPlayed(true);
          }

          videoRef.current.muted = false;
          videoRef.current.play?.();
        }
      } catch (error) {}

      setPlaying((current) => !current);
    }
  }, [playing]);

  return (
    <div
      {...storyblokEditable(blok)}
      className={classNames(
        'hero-home',
        {
          'hero-home--playing': playing,
        },
        `home-hero--illustration-${blok.illustrationSize || 'default'}`,
      )}
    >
      {isImage(blok.backgroundLeft) || isImage(blok.backgroundRight) ? (
        <div className="hero-home__background">
          <Image
            className="hero-home__background__left"
            image={blok.backgroundLeft}
            sizes="100vw"
          />
          <Image
            className="hero-home__background__right"
            image={blok.backgroundRight}
            sizes="100vw"
          />
        </div>
      ) : null}

      <MaxWidth>
        <div className="hero-home__text">
          {blok.supTitle ? <SupTitle>{blok.supTitle}</SupTitle> : null}

          {blok.title ? (
            <TitleEl
              className="hero-home__title"
              dangerouslySetInnerHTML={{
                __html: markdownToHtml(blok.title),
              }}
            />
          ) : null}

          <div className="hero-home__content">{render(blok.content)}</div>

          <div className="hero-home__buttons">
            {blok.cta.map((item, index, array) => {
              return (
                <Button
                  key={item._uid}
                  link={item.link}
                  theme={index === array.length - 1 ? 'primary' : 'white'}
                >
                  {item.title}
                </Button>
              );
            })}
          </div>
        </div>

        {blok.video?.filename ? (
          <div className="hero-home__video__player__container">
            <div className="hero-home__video__player__wrap">
              <div className="hero-home__video__player__overlay">
                <button
                  className="hero-home__video__player__overlay__button"
                  onClick={handlePlayPause}
                >
                  <img src="/icons/play.png" srcSet="/icons/play.svg" alt="" />
                </button>
              </div>

              <video
                ref={videoRef}
                className="hero-home__video__player__video"
                src={blok.video.filename}
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </div>
        ) : null}

        {blok.image?.filename ? (
          <div className="hero-home__image">
            <Image
              className="hero-home__image__image"
              image={blok.image}
              sizes="(min-width: 768px) 600px, 90vw"
              priority
            />
          </div>
        ) : null}
      </MaxWidth>
    </div>
  );
};
