'use client';

import classNames from 'classnames';
import { isValid } from 'date-fns';
import React, { useEffect } from 'react';
import useCountDown from 'react-countdown-hook';

import { COUNTDOWN_VALUES, getCountdownValues, pad } from '@/helpers/countdown';
import { useData } from '@/helpers/data';
import { useId } from '@/helpers/hooks/use-id';
import { markdownToHtml } from '@/helpers/string';

import { InlineForm } from '../inline-form';

export const CountdownFormClient = ({
  anchor = null,
  theme = 'normal',
  date,
  title,
  subtitle,
  form,
}) => {
  const emailInputIdFallback = useId(form?.emailInputId);

  return (
    <div
      className={classNames('countdown-form', `countdown-form--theme-${theme}`)}
      id={anchor}
    >
      {title ? (
        <div
          className="countdown-form__title"
          dangerouslySetInnerHTML={{ __html: markdownToHtml(title) }}
        />
      ) : null}

      {subtitle ? (
        <p className="countdown-form__subtitle">
          <label htmlFor={emailInputIdFallback}>{subtitle}</label>
        </p>
      ) : null}

      <CountDown date={date} />

      <InlineForm
        emailInputId={emailInputIdFallback}
        emailLabel={form?.emailLabel}
        submitLabel={form?.submitLabel}
        submitLabelMobile={form?.submitLabelMobile}
        successMessage={form?.successMessage}
        errorMessage={form?.errorMessage}
        codeConvertkit={form?.codeConvertkit}
      />
    </div>
  );
};

const CountDown = ({ date }) => {
  const { locale } = useData();

  const [timeLeft, { start }] = useCountDown(date - Date.now(), 1000);

  useEffect(() => {
    start();
  }, []);

  const countdownValues = getCountdownValues(timeLeft || date - Date.now());

  return isValid(date) ? (
    <div className="countdown-form__timer">
      {COUNTDOWN_VALUES.map((item) => {
        const count = countdownValues[item.key];

        return (
          <div className="countdown-form__timer__item" key={item.key}>
            <b>{pad(count, 2)}</b>

            <div>
              {item[count > 1 ? 'labelPlural' : 'label'][locale.shortCode]}
            </div>
          </div>
        );
      })}
    </div>
  ) : null;
};
