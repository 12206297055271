'use client';

import './index.css';

import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { useCallback, useRef, useState } from 'react';

import { MaxWidth } from '@/components/max-width';

export const Video = ({ blok }) => {
  const videoRef = useRef(null);

  const started = useRef(false);
  const [playing, setPlaying] = useState(false);

  const handlePlayPause = useCallback(() => {
    if (videoRef?.current) {
      try {
        if (playing) {
          videoRef.current.pause?.();
        } else {
          if (!started.current) {
            videoRef.current.currentTime = 0;

            started.current = true;
          }

          videoRef.current.muted = false;
          videoRef.current.play?.();
        }
      } catch (error) {}

      setPlaying((current) => !current);
    }
  }, [playing]);

  return (
    <div
      {...storyblokEditable(blok)}
      className={classNames('video', {
        'video--playing': playing,
      })}
    >
      <MaxWidth>
        {blok.video?.filename ? (
          <div className="video__container">
            <div className="video__wrap">
              <div className="video__overlay">
                <button
                  className="video__overlay__button"
                  onClick={handlePlayPause}
                >
                  <span>
                    <img
                      src="/icons/play-white.svg"
                      width="42"
                      height="42"
                      alt=""
                    />
                  </span>
                </button>
              </div>

              <video
                ref={videoRef}
                className="video__video"
                src={blok.video.filename}
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </div>
        ) : null}
      </MaxWidth>
    </div>
  );
};
