'use client';

import './index.css';

export const SupTitle = ({ as = 'p', children, ...props }) => {
  const El = as;

  return (
    <El
      // @ts-ignore
      className="sup-title"
      {...props}
    >
      {children}
    </El>
  );
};
