'use client';

import './index.css';
import './item.css';

import { storyblokEditable } from '@storyblok/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { Image } from '@/components/image';
import { Link } from '@/components/link';
import { MaxWidth } from '@/components/max-width';

import { isImage } from '@/helpers/image';

const CommentCardsItem = ({ item }) => {
  return (
    <div {...storyblokEditable(item)} className="comment-cards__item">
      <div className="comment-cards__item__info">
        <div className="comment-cards__item__profile">
          {isImage(item.image) ? (
            <Image
              className="comment-cards__item__picture"
              image={item.image}
              sizes="40px"
            />
          ) : (
            <div className="comment-cards__item__picture">
              {(item.name || '').slice(0, 1).toUpperCase()}
            </div>
          )}

          <div className="comment-cards__item__info__content">
            <p className="comment-cards__item__info__content__name">
              {item.name}
            </p>

            {item.info ? (
              <p className="comment-cards__item__info__content__info">
                {item.info}
              </p>
            ) : null}
          </div>
        </div>

        {item.rating ? (
          <div className="comment-cards__item__rating__wrapper">
            <p className="comment-cards__item__rating">{item.rating}</p>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
            >
              <path
                d="M5.33926 20.6667L6.82884 14.2271L1.83301 9.89583L8.43301 9.32292L10.9997 3.25L13.5663 9.32292L20.1663 9.89583L15.1705 14.2271L16.6601 20.6667L10.9997 17.2521L5.33926 20.6667Z"
                fill="#FFD905"
              />
            </svg>
          </div>
        ) : null}
      </div>

      <p className="comment-cards__item__text">{item.text}</p>
    </div>
  );
};

const splitArray = (arr) => {
  const firstThree = arr.slice(0, 3);
  const rest = arr.slice(3);
  return [firstThree, rest];
};

export const CommentCards = ({ blok }) => {
  const scrollTarget = useRef(null);

  const [first, rest] = useMemo(() => {
    return splitArray(blok.commentCardItem);
  }, [blok.commentCardItem]);

  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => {
    if (!open && scrollTarget.current) {
      const targetScrollOffsetTop =
        parseInt(
          window
            .getComputedStyle(scrollTarget.current)
            .getPropertyValue('--js-scroll-offset-top') || '0',
          10,
        ) || 0;

      const top =
        (document.scrollingElement?.scrollTop || 0) +
        scrollTarget.current.getBoundingClientRect().top -
        targetScrollOffsetTop;

      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }

    setOpen(!open);
  }, [open, setOpen]);

  return (
    <div {...storyblokEditable(blok)} className="comment-cards">
      <MaxWidth>
        {blok.rating || isImage(blok.image) ? (
          <div className="comment-cards__rating">
            <p className="comment-cards__text-rating">{blok.rating}</p>

            <Link link={blok.link}>
              <Image
                className="comment-cards__rating__icon"
                image={blok.image}
                sizes="150px"
              />
            </Link>
          </div>
        ) : null}

        {rest.length > 0 ? (
          <button className="comment-cards__see-all" onClick={toggle}>
            <span>
              {open ? 'Masquer' : blok.seeAll || 'Voir tous les avis'}
            </span>

            <img
              src="/icons/chevron-down-black.svg"
              width="24"
              height="24"
              alt=""
            />
          </button>
        ) : null}

        <div className="comment-cards__cards">
          {first.map((item) => {
            return <CommentCardsItem key={item._uid} item={item} />;
          })}
        </div>

        <div ref={scrollTarget} className="comment-cards__rest">
          {rest.length > 0 ? (
            <AnimateHeight duration={300} height={open ? 'auto' : 0}>
              <div className="comment-cards__cards">
                {rest.map((item) => {
                  return <CommentCardsItem key={item._uid} item={item} />;
                })}
              </div>
            </AnimateHeight>
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};
