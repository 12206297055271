'use client';

import './index.css';

import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';

import { MaxWidth } from '@/components/max-width';

import { hasText, render } from '@/helpers/storyblok/rich-text';
import { markdownToHtml } from '@/helpers/string';

const TarteItem = ({ blok }) => {
  return (
    <div className="tarte__item" {...storyblokEditable(blok)}>
      {blok.label ? <p className="tarte__item__label">{blok.label}</p> : null}

      {blok.title ? (
        <p
          className="tarte__item__title"
          dangerouslySetInnerHTML={{
            __html: markdownToHtml(blok.title),
          }}
        />
      ) : null}
    </div>
  );
};

export const Tarte = ({ blok }) => {
  const TitleEl = blok.titleLevel || 'h2';

  return (
    <div {...storyblokEditable(blok)} className={classNames('tarte')}>
      <MaxWidth>
        <div className="tarte__wrap">
          {blok.title ? (
            <TitleEl
              className="tarte__title"
              dangerouslySetInnerHTML={{
                __html: markdownToHtml(blok.title),
              }}
            />
          ) : null}

          {blok.items?.length > 0 ? (
            <div className="tarte__items">
              {(blok.items || []).map((item) => {
                return <TarteItem key={item._uid} blok={item} />;
              })}
            </div>
          ) : null}

          {hasText(blok.footer) ? (
            <div className="tarte__footer">{render(blok.footer)}</div>
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};
