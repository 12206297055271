'use client';

import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import * as EmailValidator from 'email-validator';
import { useMemo, useState } from 'react';
import Spinner from 'react-svg-spinner';

import { useId } from '@/helpers/hooks/use-id';

import './index.css';

import { usePathname } from 'next/navigation';

delete Spinner.defaultProps;

const STATE = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const InlineForm = ({
  emailInputId = null,
  emailLabel,
  submitLabel,
  submitLabelMobile,
  successMessage,
  errorMessage,
  codeConvertkit,
}) => {
  const pathName = usePathname();

  const [state, setState] = useState(STATE.IDLE);

  const emailInputIdFallback = useId(emailInputId);
  const [email, setEmail] = useState('');

  const canSubmit = useMemo(() => {
    return EmailValidator.validate(email);
  }, [email]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setState(STATE.LOADING);

    const data = {
      email,
      formId: codeConvertkit || null,
      pathName,
    };

    try {
      const response = await fetch('/api/countdown', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Countdown form submit error`);
      }

      setState(STATE.SUCCESS);

      Sentry.withScope((scope) => {
        scope.setExtras(data);
        Sentry.captureMessage('Countdown form submitted successfully');
      });
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtras(data);
        Sentry.captureException(error);
      });

      setState(STATE.ERROR);
    }
  };

  return (
    <form
      className={classNames('inline-form', {
        'inline-form--state-success': state === STATE.SUCCESS,
      })}
      onSubmit={handleSubmit}
    >
      <div className="inline-form__wrap">
        <div className="inline-form__input">
          <input
            id={emailInputIdFallback}
            className="inline-form__input__email"
            type="email"
            autoComplete="email"
            placeholder={emailLabel}
            disabled={[STATE.LOADING, STATE.SUCCESS].includes(state)}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          <button
            type="submit"
            className="inline-form__input__submit"
            disabled={
              !canSubmit || [STATE.LOADING, STATE.SUCCESS].includes(state)
            }
          >
            {state === STATE.LOADING ? (
              <Spinner
                color="var(--submit-color)"
                speed="fast"
                size="16"
                gap={4}
                thickness={4}
              />
            ) : submitLabel && submitLabelMobile ? (
              <>
                <span className="desktop-only">{submitLabel}</span>
                <span className="mobile-only">{submitLabelMobile}</span>
              </>
            ) : (
              submitLabel || submitLabelMobile
            )}
          </button>
        </div>

        {state === STATE.SUCCESS ? (
          <div className="inline-form__success">
            <p>{successMessage}</p>
          </div>
        ) : null}
      </div>

      {state === STATE.ERROR ? (
        <p className="inline-form__error">{errorMessage}</p>
      ) : null}
    </form>
  );
};
