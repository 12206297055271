import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';

import { Image } from '@/components/image';

import { hasText, render } from '@/helpers/storyblok/rich-text';
import { markdownToHtml } from '@/helpers/string';

import './item.css';

import { SupTitle } from '@/components/sup-title';

export const FutureCoursesItem = ({ blok }) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={classNames('future-courses__item', 'keen-slider__slide')}
    >
      <Image
        className="future-courses__item__image"
        image={blok.image}
        sizes="(min-width: 769px) 400px, 100vw"
      />

      <div className="future-courses__item__content">
        <div className="future-courses__item__header">
          <SupTitle>{blok.supTitle}</SupTitle>

          <ul className="future-courses__item__header__emojis">
            {blok.emojis.map((emoji) => {
              return (
                <li key={emoji.id}>
                  <Image image={emoji} sizes="24px" />
                </li>
              );
            })}
          </ul>
        </div>

        <p
          className="future-courses__item__title"
          dangerouslySetInnerHTML={{
            __html: markdownToHtml(blok.title),
          }}
        />

        {blok.tagItems?.length > 0 ? (
          <div className="future-courses__item__tags">
            {(blok.tagItems || []).map((tag) => {
              return (
                <div
                  key={tag._uid}
                  {...storyblokEditable(tag)}
                  className={classNames(
                    'future-courses__item__tag',
                    `future-courses__item__tag--style-${tag.tagStyle}`,
                  )}
                >
                  {tag.tag}
                </div>
              );
            })}
          </div>
        ) : null}

        {hasText(blok.description) ? (
          <div className="future-courses__item__description">
            {render(blok.description)}
          </div>
        ) : null}
      </div>
    </div>
  );
};
